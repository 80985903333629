import React, { useState, useRef, useEffect } from "react";
import Transcription from "./transcription/index";
import Video from "./video/index";
import RecordRTC from "recordrtc";
import { useLocation } from "react-router-dom";
import Header from "../component/header";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import thankYou_Message from "./../assets/audio/thankyou.json";
import PopupModal from "../component/PopupModal/PopupModal";
import { useNavigate, useParams } from "react-router-dom";

const Interview = ({ handle, isFullScreen, setIsFullScreen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const interviewStatus = localStorage.getItem("interview_status");
  const [currentInterviewStatus, setCurrentInterviewStatus] = useState("");
  const videoRef = useRef(null);
  const [chat, setChat] = useState([]);
  const [microphone, setMicrophone] = useState(false);
  const [writeAi, setWriteAi] = useState(false);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);
  const [screen, setScreen] = useState(null);
  const [camera, setCamera] = useState(null);
  const [startDisable, setStartDisable] = useState(false);
  const [stopDisable, setStopDisable] = useState(true);
  const recorderRef = useRef(null);
  const audioRecorderRef = useRef(null);

  const [recordingPermission, SetRecordingPermission] = useState(true);
  const [startRecord, setStartRecord] = useState(false);
  const [pauseRecord, setPauseRecord] = useState(false);
  const firstTimeCall = useRef(false);
  const [aiAudioStream, setAiAudioStream] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [socket, setSocket] = useState(null);
  const [is_completed, setIsCompleted] = useState(null);
  const [popupendCall, setPopupendCall] = useState(false);
  const [callcut, setCallcut] = useState(false);

  useEffect(() => {
    window.history.go(1);
  }, []);

  const getMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30, max: 90 },
          aspectRatio: 16 / 9,
        },
        audio: true,
      });
      setMediaStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      const options = {
        type: "video",
        mimeType: "video/webm;codecs=vp9", // using vp9 for better compression
        timeSlice: 5000,
        bitsPerSecond: 128000,
      };
    } catch (err) {
      console.error("Error accessing media devices.", err);
    }
  };
  useEffect(() => {
    if (interviewStatus !== "completed") {
      getMedia();
    } else {
      try {
        if (mediaStream) {
          navigate(`/thank-you/`, { state: { from: "interview" } });
          mediaStream.getTracks().forEach((track) => track.stop());
        }
      } catch (error) {}
    }
  }, []);
  function releaseResource() {
    try {
      if (mediaStream) {
        navigate(`/thank-you/`, { state: { from: "interview" } });
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (currentInterviewStatus === "completed") {
      localStorage.setItem("interview_status", "completed");
      releaseResource();
    }
  }, [currentInterviewStatus]);
  const mergeStreams = (userStream, aiStream) => {
    try {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      if (userStream === null) {
        getMedia();
        return;
      }
      const userAudio = audioContext.createMediaStreamSource(userStream); // my steam
      const aiAudio = audioContext.createMediaStreamSource(aiStream); // remote stream

      const destination = audioContext.createMediaStreamDestination(); // 
      userAudio.connect(destination);
      aiAudio.connect(destination);

      const mergedAudioStream = new MediaStream([
        ...destination.stream.getAudioTracks(),
      ]);
      const mergedVideoStream = new MediaStream([
        ...userStream.getVideoTracks(),
      ]);
      var finalStream = new MediaStream([
        ...mergedVideoStream.getVideoTracks(),
        ...mergedAudioStream.getAudioTracks(),
      ]);
      return finalStream;
    } catch (error) {
      console.error("Error merging streams:", error);
      return null;
    }
  };

  useEffect(() => {
    if (interviewStatus === "completed") {
      releaseResource();
    }
    if (aiAudioStream && aiAudioStream != null) {
      if (
        (!mediaStream && !aiAudioStream) ||
        mediaStream == null ||
        aiAudioStream == null
      ) {
        return;
      }

      var combinedStream = mergeStreams(mediaStream, aiAudioStream);

      const videoOptions = {
        type: "video",
        mimeType: "video/webm",
        audio: true,
        timeSlice: 5000,
        ondataavailable: (blob) => {
          socket.send(blob);
        },
        bitsPerSecond: 512000,
      };
      if (recorderRef.current) {
        recorderRef.current.stopRecording();
      }
      recorderRef.current = RecordRTC(combinedStream, videoOptions);
      recorderRef.current.startRecording();
    }
  }, [aiAudioStream]);

  const startScreenRecord = async () => {
    const session_id = localStorage.getItem("session_id");
    setSocket(
      new W3CWebSocket(`${process.env.REACT_APP_SOCKET_URL}${session_id}`)
    );
    SetRecordingPermission(true);
    await setStopDisable(false);
    await setStartDisable(true);
    setStartRecord(true);
  };

  const stop = async () => {
    await setStartDisable(true);
    if (recorderRef.current) {
      recorderRef.current.stopRecording(stopRecordingCallback);
      setStartRecord(false);
      if (socket) {
        socket.close();
      }

      // const byteCharacters = atob(thankYou_Message.audioContent);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // const byteArray = new Uint8Array(byteNumbers);
      // const blob = new Blob([byteArray], { type: "audio/mp3" });

      // const audioUrl = URL.createObjectURL(blob);
      // const audio = new Audio(audioUrl);
      // audio.play();
      navigate(`/thank-you/`, { state: { from: "interview" } });
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(null);
      }
    }
  };

  const stopRecordingCallback = async () => {
    if (recorderRef.current) {
      let recordedVideoUrl;
      if (recorderRef.current.getBlob()) {
        recordedVideoUrl = URL.createObjectURL(recorderRef.current.getBlob());
        setRecordedVideoUrl(recordedVideoUrl);
      }
      setStartDisable(false);
      setStopDisable(true);
      setScreen(null);
      setCamera(null);
      if (recorderRef.current.screen) {
        recorderRef.current.screen.stop();
      }
      recorderRef.current.destroy();
      recorderRef.current = null;
    }
  };

  useEffect(() => {
    if (!firstTimeCall.current) {
      startScreenRecord();
      firstTimeCall.current = true;
    }
  }, []);

  useEffect(() => {
    if (is_completed === true) {
      const byteCharacters = atob(thankYou_Message.audioContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "audio/mp3" });

      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);
      audio.play();
    }
  }, [is_completed]);

  const pauseRecording = async () => {
    recorderRef.current.pauseRecording();
    setPauseRecord(true);
  };

  const resumeRecording = async () => {
    recorderRef.current.resumeRecording();
    setPauseRecord(false);
  };

  const handleEndCallClickMain = async () => {
    setCallcut(true);
  };
  return (
    <div>
      <PopupModal
        setPopupendCall={setPopupendCall}
        popupendCall={popupendCall}
        handle={handle}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        handleEndCallClickMain={handleEndCallClickMain}
      />
      <Header />

      {recordedVideoUrl || interviewStatus === "completed" ? (
        <div className='video-preview  rounded-2xl justify-center items-center flex lg:h-[93vh] h-[90vh] antialiased text-gray-800'>
          <div className='text-center py-4 lg:px-4 '>
            <div
              className='flex lg:inline-flex text-lime-100  border-t-4 border-lime-500 rounded-b text-lime-900 px-4 py-3 shadow-md'
              role='alert'
            >
              <div className='flex'>
                <div>
                  <p className='font-bold'>Thank You</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='grid lg:grid-cols-4 md:grid-cols-12 antialiased p-2 sm:px-6 lg:px-8'>
          <div className='md:col-span-7 lg:col-span-3 col-span-12 relative h-full'>
            <div className='relative flex flex-col items-center justify-center text-center text-white py-0 px-3'>
              <div className='video-docker md:absolute top-0 left-0 w-full'>
                <Video
                  pauseRecord={pauseRecord}
                  startRecord={startRecord}
                  resumeRecording={resumeRecording}
                  pauseRecording={pauseRecording}
                  recordingPermission={recordingPermission}
                  stopDisable={stopDisable}
                  stop={stop}
                  chat={chat}
                  setChat={setChat}
                  setMicrophone={setMicrophone}
                  setWriteAi={setWriteAi}
                  setAiAudioStream={setAiAudioStream}
                  callcut={callcut}
                  handle={handle}
                  setIsFullScreen={setIsFullScreen}
                  handleEndCallClickMain={handleEndCallClickMain}
                  setPopupendCall={setPopupendCall}
                  setCurrentInterviewStatus={setCurrentInterviewStatus}
                />
              </div>
            </div>
          </div>
          <div className='md:col-span-5 lg:col-span-1 col-span-12 max-md:pt-[10px]'>
            <div className='m-0 p-0 w-full'>
              <div className='flex flex-col flex-auto h-full max-h-[845px]'>
                <Transcription
                  setChat={setChat}
                  chat={chat}
                  microphone={microphone}
                  writeAi={writeAi}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Interview;
