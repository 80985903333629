import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../context/SocketProvider";
import ReactPlayer from "react-player";
import Header from "../../header";
const ExternalInterviewLobbyScreen = ({}) => {
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState("");
  const [myStream, setMyStream] = useState(null);
  const userType = localStorage.getItem("userType");
  const socket = useSocket();
  const guideLinesRef = useRef(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      if (guideLinesRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = guideLinesRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setIsScrolledToBottom(true);
          setButtonDisable(true);
        }
      }
    };

    const guideLinesElement = guideLinesRef.current;
    if (guideLinesElement) {
      guideLinesElement.addEventListener("scroll", handleScroll);
      return () =>
        guideLinesElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
          video: true,
        });
        setMyStream(stream);
      } catch (err) {
        console.error("Error accessing media devices.", err);
      }
    };
    getMediaStream();
  }, []);

  const navigate = useNavigate();
  const handleSubmitForm = useCallback(
    (e) => {
      e.preventDefault();
      setError("");
      if (socket && socket.readyState === WebSocket.OPEN) {
        const emailValue = localStorage.getItem("resourceful_video_call_id");
        const roomValue = localStorage.getItem("resourceful_video_call_room");
        localStorage.setItem("email", emailValue);
        socket.send(
          JSON.stringify({
            type: "room:join",
            email: emailValue,
            room: roomValue,
          })
        );
      }
    },
    [email, room, socket]
  );

  const handleJoinRoom = useCallback(
    (data) => {
      const { email, room } = data;
      if(myStream) {
        myStream.getTracks().forEach((track) =>{
          track.stop();
         });
        navigate(`/external-interview-room/${room}`);
      } else {
        setError("We cant't find your camera");
      }
    },
    [navigate, myStream]
  );

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "room:join") {
          handleJoinRoom(data);
        }
      };
    }
  }, [socket, handleJoinRoom]);
  return (
    <div>
      <Header />
      <div className="md:flex lg:w-11/12 mx-auto">
        <div className="md:w-[50%] Main_Container self-center p-3">
          <div className="bg-gray-100 rounded-xl guide_line_bg_box join-div">
            <div className="container mx-auto">
              <h2 className="text-center text-2xl font-semibold mb-8 main_heading_text">
                Guidelines
              </h2>
              <div className="guide_line_container join-div" ref={guideLinesRef}>
                <h3 className="text-left heading_text">
                  <b>Instructions</b>
                </h3>
                <p className="text-left paragraph_text">
                  Complete your video screening successfully and make a positive
                  impression on the hiring manager. Please follow these tips and
                  instructions:
                </p>
                <div className="text-left px-6">
                  <h5 className="text-left mt-2">
                    <b>● Create a Distraction-Free Zone </b>
                  </h5>
                  <p className="text-left paragraph_text">
                    Ensure you are in a quiet, distraction-free area and maintain
                    eye contact with the camera.
                  </p>
                  <h5 className="text-left mt-2">
                    <b>● Stay on the Interview Screen</b>
                  </h5>
                  <p className="text-left paragraph_text">
                    Do not switch tabs or refresh the screen during the interview.
                    Ensure you stay on this tab throughout the process.
                  </p>
                  <h5 className="text-left mt-2">
                    <b> ● Be Aware of Monitoring</b>
                  </h5>
                  <p className="text-left paragraph_text">
                    Your interview will be recorded and reviewed by the hiring
                    manager. Maintain a professional demeanor throughout to leave
                    a positive impression.
                  </p>
                  <h5 className="text-left mt-2">
                    <b> ● Position Yourself Clearly</b>
                  </h5>
                  <p className="text-left paragraph_text">
                    Ensure your upper body is clearly visible on camera. Avoid
                    unnecessary body, face, eye, or hand movements, and do not
                    move away from the camera to maximize your job opportunity.
                  </p>
                </div>
                <h4 className="text-left mt-2 heading_text">
                  <b>Grant Permissions</b>
                </h4>
                <p className="text-left paragraph_text">
                  We require your permission to access the following tools for the
                  interview. These permissions must remain enabled until the
                  interview ends:
                </p>
                <ul className="text-left px-6 paragraph_text">
                  <li>● Enable Camera</li>
                  <li>● Enable Microphone</li>
                  <li>● Enable Audio</li>
                </ul>
              </div>
            </div>
          <div className="text-center mt-6">
          </div>
          </div>
        </div>

        <div className='md:w-[50%] Main_Container self-center p-3'>
        <div className="bg-gray-100 rounded-xl guide_line_bg_box join-div">
        <div className="container mx-auto">
          <div className='relative w-full'>
            <div className='flex flex-col justify-center items-center'>
              <div className='video-screen'>
                {myStream && (
                  <ReactPlayer
                    className='mx-auto'
                    playing
                    url={myStream}
                    width='100%'
                    height='auto'
                    muted
                  />
                )}
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
      </div>
      <div className="pb-5">
        <form className="mt-4 text-center">
          <div className="h-[60px]">
            <p className={`p-2 ${buttonDisable && error ?'text-red-600' : "hidden"}`}>{error}</p>
            <p className={`p-2 ${!buttonDisable && buttonHover ? "text-red-600" : "hidden"}`}>Please view Guidelines section before click 'Agree and Continue'.</p>
          </div>
          <button
            type="button"
            className={`shadow focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded ${
              buttonDisable ? "accept_button" : "disable_accept_button"
            }`}
            onMouseOver={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
            disabled={!buttonDisable}
            onClick={(e) => {
              setEmail("abc@gmail.com");
              setRoom("123");
              handleSubmitForm(e);
            }}
          >
            Agree and Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default ExternalInterviewLobbyScreen;