import React from "react";
import logo from "./../../assets/image/logo.png";
const Header = () => {
    return (
        <nav className="bg-gray-100">
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center">
                            <img className="h-8 w-auto" src={logo} alt="Your Company"/>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
