import CryptoJS from "crypto-js";

function encryptMessage(message) {
  try {
    // console.log(message);
    const encryptionKey = process.env.REACT_APP_KEY;
    const encryptionIV = process.env.REACT_APP_IV;

    // Convert key and IV to WordArray
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(encryptionIV);

    // Convert message to a WordArray
    const messageWordArray = CryptoJS.enc.Utf8.parse(message);

    // Encrypt
    const encrypted = CryptoJS.AES.encrypt(messageWordArray, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // console.log("encrypted", encrypted);

    // Encode the encrypted WordArray to base64
    const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    // console.log("encryptedBase64", encryptedBase64);
    return encryptedBase64;
  } catch (error) {
    console.error("###############Error occurred:", error);
  }
}

export default encryptMessage;
