import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactPlayer from "react-player";
import peer from "../../../services/peer";
import { useSocket } from "../../../context/SocketProvider";
import Header from "../../header";
import microphoneSlash from "./../../../assets/image/microphone_slash.svg";
import microphone from "./../../../assets/image/microphone.svg";
import userEvent from "@testing-library/user-event";
import endCall from "./../../../assets/image/end_call.svg";
import startRecord from "./../../../assets/image/record_start.svg";
import stopRecord from "./../../../assets/image/record_stop.svg";
import { useNavigate, useParams } from "react-router-dom";

const VideoCallRoom = () => {
  const [remoteSocketId, setRemoteSocketId] = useState("");
  const [myStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState();
  const navigate = useNavigate();
  const [isCalling, setIsCalling] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);
  const [muted, setMuted] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const email = localStorage.getItem("email");
  const userTypeValue = localStorage.getItem("userType");
  const staffFirstName = localStorage.getItem("staffFirstName");
  const userFirstName = localStorage.getItem("userFirstName");
  const socket = useSocket();
  const [userType, setUserType] = useState("");
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const mediaChunks = useRef([]);
  const room_id = localStorage.getItem("resourceful_video_call_room");
  const [recordSocket, setRecordSocket] = useState(null);
  const [isRecordSocketReady, setIsRecordSocketReady] = useState(false);
  const [remoteMute, setRemoteMute] = useState(false);

  const mediaStreamUserRef = useRef();
  const saveRocording = localStorage.getItem("saveRocording");
  let numberOfMediaStream = [];
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);
  useEffect(() => {
    // Set up a flag for refresh detection
    const handleBeforeUnload = () => {
      sessionStorage.setItem('isPageRefreshed', 'true');
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Check if the page was refreshed
    if (sessionStorage.getItem('isPageRefreshed') === 'true') {
      setIsPageRefreshed(true);
      if (recording) {
        recorder.stop();
        setRecording(false);
      }
      
      navigate(`/video-call-lobby?id=${email}&room=${room_id}`);
      sessionStorage.removeItem('isPageRefreshed');
    }
     // Clean up event listener
     return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function stopWebcam() {
    console.log("numberOfMediaStream", numberOfMediaStream);
    numberOfMediaStream.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
    numberOfMediaStream = [];
  }

  const genrateDelay = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    console.log("recordSocket", recordSocket);
    if (!recordSocket) {
      var recordSockeConnection = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/video_call_recording/${room_id}/`
      );
      setRecordSocket(recordSockeConnection);
    }
  }, [recordSocket, isRecordSocketReady]);

  const [delay, setDelay] = useState(0);
  const stopStreamTracks = (stream) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleRecording = async () => {
    if (recording) {
      recorder.stop();
      setRecording(false);
    } else {
      // const combinedStream = new MediaStream();
      if (!recordSocket) {
        setIsRecordSocketReady(!isRecordSocketReady);
      }
      var displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      // await genrateDelay(3000);
      const audioContext = new AudioContext();
      const audioDestination = audioContext.createMediaStreamDestination();
      console.log("@@@@ InsideRecording myStream : ", myStream); //undefine
      if (myStream) {
        const myAudioTracks = myStream.getAudioTracks();

        myAudioTracks.forEach((track) => {
          const source = audioContext.createMediaStreamSource(
            new MediaStream([track])
          );
          source.connect(audioDestination);
        });
      }
      console.log("@@@@ InsideRecording remoteStream : ", remoteStream);

      if (remoteStream) {
        const remoteAudioTracks = remoteStream.getAudioTracks();
        remoteAudioTracks.forEach((track) => {
          const source = audioContext.createMediaStreamSource(
            new MediaStream([track])
          );
          source.connect(audioDestination);
        });
      }
      console.log("@@@@ InsideRecording displayStream : ", displayStream);
      console.log("@@@@ InsideRecording audioDestination : ", audioDestination);

      const combinedStream = new MediaStream([
        ...displayStream.getVideoTracks(),
        ...audioDestination.stream.getAudioTracks(),
      ]);
      console.log("@@@@ InsideRecording combinedStream : ", combinedStream);

      const newRecorder = new MediaRecorder(combinedStream);
      console.log("@@@@ InsideRecording newRecorder : ", newRecorder);
      newRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log("event.data", event.data);
          mediaChunks.current.push(event.data);
          recordSocket.send(event.data);
        }
      };

      newRecorder.onstop = () => {
        console.log("@@@@ InsideRecording onstop mediaChunks : ", mediaChunks);

        const blob = new Blob(mediaChunks.current, { type: "video/webm" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        // a.style = "display: none";
        // a.href = url;
        // a.download = "recording.webm";
        // a.click();
        // window.URL.revokeObjectURL(url);
        stopStreamTracks(displayStream);
        // displayStream = null;
      };

      newRecorder.start(5000);
      setRecorder(newRecorder);
      setRecording(true);
      console.log("@@@@ InsideRecording recorder : ", recorder);
      console.log("@@@@ InsideRecording recording : ", recording);
    }
  };

  if (delay === 0) {
    if (email === "19196dd3-b283-4803-bd8f-e45e5a58865b") {
      setUserType("staff");
      setDelay(250);
    } else {
      setUserType("candidate");
      setDelay(500);
    }
  }

  console.log(recorder);
  useEffect(() => {
    if (peer.peer) {
      peer.peer.onicecandidate = (event) => {
        console.log("event.candidate", event.candidate);
        if (event.candidate) {
          socket.send(
            JSON.stringify({
              type: "ice:candidate",
              to: remoteSocketId,
              candidate: event.candidate,
            })
          );
        }
      };
    }
  }, [remoteSocketId, socket]);

  console.log("userType", userType);

  const handleUserJoined = useCallback(({ email, id }) => {
    console.log(`Email ${email} joined room ${id}`);
    setRemoteSocketId(id);
    setIsCalling(true);
  }, []);

  function checkEmail(socket_email, socket_id) {
    if (socket_email === email) {
      localStorage.setItem("my_socket_id", socket_id);
      return true;
    }
    return false;
  }

  const handleIncommingCall = useCallback(
    async ({ from, offer }) => {
      setRemoteSocketId(from);
      console.log("Fetching camera acccessfor INcoming call");
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: true,
      });
      numberOfMediaStream.push(stream);
      setMyStream(stream);
      mediaStreamUserRef.current = stream;
      const ans = await peer.getAnswer(offer);
      socket.send(
        JSON.stringify({
          type: "call:accepted",
          to: from,
          ans,
        })
      );
      setIsAnswering(true);
      // sendStreams(stream);
      console.log("52 myStream", myStream);
      console.log("53 remoteSocketID", remoteSocketId);
    },
    [socket]
  );
  console.log("Mystream", myStream);
  const handleCallUser = useCallback(async () => {
    console.log("Fetching camera acccessfor Call User");

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      },
      video: true,
    });
    numberOfMediaStream.push(stream);

    console.log("remoteSocketId", remoteSocketId);
    if (remoteSocketId) {
      const offer = await peer.getOffer();
      socket.send(
        JSON.stringify({
          type: "user:call",
          to: remoteSocketId,
          offer,
        })
      );
      setMyStream(stream);
      mediaStreamUserRef.current = stream;

      // sendStreams(stream);
      console.log("73 myStream", myStream);
    }
  }, [remoteSocketId]);

  // const sendStreams = useCallback(async () => {
  useEffect(() => {
    function sendStream(myStream) {
      if (myStream) {
        for (const track of myStream.getTracks()) {
          peer.peer.addTrack(track, myStream);
        }
      }
    }
    const timer = setTimeout(() => {
      console.log("Executing code after 1 second delay due to call acceptance");
      console.log("myStream", myStream);
      if (myStream) {
        sendStream(myStream);
      } else {
        console.log("No Stream");
      }
      // }
    }, 1000);
    return () => clearTimeout(timer);
  }, [isCallAccepted]);

  const handleCallAccepted = useCallback(async ({ from, ans }) => {
    try {
      // if (peer.peer.signalingState !== "stable") {
      //   //HAVE-LOCAL-OFFER
      //   console.log("Call accepted but already in stable state");
      //   return;
      // }
      console.log(
        "handleCallAccepted => peer.peer.signalingState : ",
        peer.peer.signalingState
      );
      await peer.setLocalDescription(ans);
      console.log("CallAccepted myStream:", myStream);
      console.log("CalslAccepted remoteStream:", remoteStream);
      console.log("Call Accepted and local description set!");
      setIsCallAccepted(true);
    } catch (error) {
      console.error("Error setting local description:", error);
    }
  }, []);

  const handleNegoNeeded = useCallback(async () => {
    const offer = await peer.getOffer();
    // delay;/
    console.log("delay", delay);
    // setTimeout(() => {
    //   socket.send(
    //     JSON.stringify({
    //       type: "peer:nego:needed",
    //       to: remoteSocketId,
    //       offer,
    //     })
    //   );
    // }, delay);

    socket.send(
      JSON.stringify({
        type: "peer:nego:needed",
        to: remoteSocketId,
        offer,
      })
    );
  }, [remoteSocketId, socket]);

  useEffect(() => {
    if (peer.peer) {
      peer.peer.addEventListener("negotiationneeded", handleNegoNeeded);
    }
    return () => {
      // console.log()
      if (peer.peer) {
        peer.peer.removeEventListener("negotiationneeded", handleNegoNeeded);
      }
    };
  }, [handleNegoNeeded]);

  useEffect(() => {
    const updateRemoteAudioState = (isMuted) => {
      console.log(
        "###remoteStream.getAudioTracks ",
        remoteStream?.getAudioTracks
      );
      if (remoteStream) {
        console.log(
          "@@@@@remoteStream.getAudioTracks",
          remoteStream.getAudioTracks
        );
        remoteStream.getAudioTracks().forEach((track) => {
          console.log("Muting remote Stream ", isMuted);
          track.enabled = !isMuted;
        });
      }
      console.log(
        "@@@@@remoteStream.getAudioTracks ",
        remoteStream?.getAudioTracks
      );
    };
    updateRemoteAudioState(remoteMute);
    const checkAudioTrack = (stream) => {
      const audioTracks = stream.getAudioTracks();
      // console.log("audioTracks", audioTracks);
      const isAudioEnabled = audioTracks.some((track) => track.enabled);
      // console.log("isAudioEnabled", isAudioEnabled);
      //  setHasAudio(audioTracks.length > 0);
    };

    const onTrack = (ev) => {
      const currentRemoteStream = ev.streams[0];
      console.log("currentRemoteStream", currentRemoteStream);
      setRemoteStream(currentRemoteStream);
      checkAudioTrack(currentRemoteStream);

      // Check the audio track continuously
      // const intervalId = setInterval(() => {
      //   checkAudioTrack(currentRemoteStream);
      // }, 1000); // Adjust the interval as needed

      // Clear the interval when component unmounts or stream changes
      // return () => clearInterval(intervalId);
    };
    peer.peer.addEventListener("track", onTrack);
    // return () => {
    //   peer.peer.removeEventListener("track", onTrack);
    // };
    // peer.peer.addEventListener("track", (ev) => {
    //   const currentRemoteStream = ev.streams;
    //   setRemoteStream(currentRemoteStream[0]);
    // });
  }, [remoteMute]);

  const handleNegoNeedIncomming = useCallback(
    async ({ from, offer }) => {
      try {
        // while (peer.peer.signalingState !== "stable") {
        //   console.log("Connection not stable, waiting...");
        //   await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed
        // }
        console.log("Incoming offer:", offer);
        console.log("@@from", from);

        const ans = await peer.getAnswer(offer);
        socket.send(
          JSON.stringify({
            type: "peer:nego:done",
            to: from,
            ans,
          })
        );
      } catch (error) {
        console.error("Error handling incoming negotiation:");
        console.log("peer.peer.signalingState", peer.peer.signalingState);
        console.log("193##from", from);

        console.error("Error handling incoming negotiation:", error);
      }
    },
    [socket]
  );

  const handleNegoNeedFinal = useCallback(async ({ from, ans }) => {
    try {
      await peer.setLocalDescription(ans);
    } catch (error) {}
  }, []);
  const handleEndCallSocket = useCallback(async () => {
    try {
      console.log("SOCKET recording", recording);
      console.log("SOCKET recorder", recorder);
      if (recording) {
        console.log("STOP RECORDING SOCKET");
        recorder.stop();
        setRecording(false);
        recordSocket.close();
      }

      stopStreamTracks(myStream);
      stopStreamTracks(remoteStream);
      stopWebcam(numberOfMediaStream);
      // stopStreamTracks(displayStream);
      if (mediaStreamUserRef.current) {
        mediaStreamUserRef.current.getTracks().forEach((track) => track.stop());
        console.log("Stream stopped.");
      }
      setMyStream(null);
      setRemoteStream(null);
      setRemoteSocketId("");

      socket.close();
      if (peer) {
        console.log("closing peer connection");

        // console.log("peer", peer);
        peer.close();
      }
      navigate("/thank-you", { state: { from: "VideoCall" } });
    } catch (error) {
      console.error("Error end:call", error);
    }
  }, []);
  var countValue = 0;
  useEffect(() => {
    if (socket) {
      socket.onmessage = async (e) => {
        console.log("numberOfMediaStream", numberOfMediaStream);
        const response = JSON.parse(e.data);
        const type = response.type;
        if (type === "user:joined") {
          const email = response?.email;
          const id = response?.id;
          var IsNewEmail = checkEmail(email, id);
          if (!IsNewEmail) {
            handleUserJoined({ email, id });
          }
        } else if (type === "incomming:call") {
          const offer = response?.offer;
          const from = response?.from;
          handleIncommingCall({ from, offer });
          // console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "call:accepted") {
          const ans = response?.ans;
          const from = response?.from;
          handleCallAccepted({ from, ans });
          // console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "peer:nego:needed") {
          const offer = response?.offer;
          const from = response?.from;
          handleNegoNeedIncomming({ from, offer });
          // console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "peer:nego:final") {
          const ans = response?.ans;
          const from = response?.from;

          handleNegoNeedFinal({ from, ans });
          // console.log("peer.peer.signalingState", peer.peer.signalingState);
        } else if (type === "ice:candidate") {
          // console.log("272 Response,", response);
          const candidate = response?.candidate;
          const from = response?.from;
          peer
            .addIceCandidate(candidate)
            .catch((e) =>
              console.error("278 Error adding received ICE candidate", e)
            );

          if (
            userTypeValue === "staff" &&
            countValue === 0 &&
            saveRocording === "1"
          ) {
            console.log(userTypeValue);
            countValue = 1;

            await genrateDelay(500);

            // handleRecording(); // Assuming handleRecording is a defined function

            document.getElementById("hiddenRecordButton").click();
          }
          // handleNegoNeedFinal({ from, ans });
        } else if (type === "end:call") {
          // const from = response?.from;
          console.log(
            'document.getElementById("endcallbutton")',
            document.getElementById("endcallbutton")
          );
          document.getElementById("endcallbutton").click();
          // handleEndCallSocket();
        } else if (type === "mute:state") {
          console.log("type", type);
          const isMuted = response?.isMuted;
          const from = response?.from;
          console.log("remoteStream", remoteStream);
          setRemoteMute(isMuted);
          // updateRemoteAudioState(isMuted);
        }
      };
    }
  }, [socket]);

  useEffect(() => {
    handleCallUser();
  }, [remoteSocketId]);

  const toggleMuted = () => {
    if (myStream) {
      myStream.getAudioTracks().forEach((track) => {
        // console.log("track", track);
        track.enabled = !track.enabled;
      });
    }
    console.log(
      "!myStream.getAudioTracks()[0].enabled",
      !myStream.getAudioTracks()[0].enabled
    );
    socket.send(
      JSON.stringify({
        type: "mute:state",
        to: remoteSocketId,
        isMuted: !myStream.getAudioTracks()[0].enabled,
      })
    );
    setMyStream(myStream);
    setMuted(!muted);
  };

  const handleEndCallClick = (e) => {
    console.log(e);
    // console.log("button clicked");
    console.log("Button recording", recording);
    console.log("Button recorder", recorder);

    if (recording) {
      console.log("STOP RECORDING Button");
      recorder.stop();
      setRecording(false);
      recordSocket.close();
    }
    stopStreamTracks(myStream);
    stopStreamTracks(remoteStream);
    stopWebcam(numberOfMediaStream);
    if (mediaStreamUserRef.current) {
      mediaStreamUserRef.current.getTracks().forEach((track) => track.stop());
      console.log("Stream stopped.");
    }
    setMyStream(null);
    setRemoteStream(null);
    setRemoteSocketId("");
    if (peer) {
      console.log("closing peer connection");

      console.log("peer", peer);
      peer.close();
    }
    socket.send(
      JSON.stringify({
        type: "end:call",
        to: remoteSocketId,
      })
    );
    socket.close();

    // if (peer) {
    //   console.log("closing peer connection");
    //   peer.close();
    //   peer = null;
    // }
    navigate("/thank-you", { state: { from: "VideoCall" } });
  };

  return (
    <div className="bg-[#202124]">
      <Header />
      <div className="bg-[#202124] video-fix-height-show p-3">
        {remoteSocketId ? (
          <div className='text-center flex justify-center items-center'>
            <div className='relative h-full max-md:w-full md:w-[124vh]'>
              <div className='absolute bottom-2 flex justify-left z-[9999]'>
                <h4 className='text-[20px] text-left   text-white bg-black/[0.6] p-1 pl -3'>
                  {userTypeValue === "staff" ? userFirstName : staffFirstName}
                </h4>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div className='absolute bottom-2 flex justify-center  z-[9999]'>
                  {remoteStream && (
                    <>
                      {muted ? (
                        <button
                          type='button'
                          onClick={toggleMuted}
                          className='bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full z-10'
                        >
                          <img
                            src={microphoneSlash}
                            className='w-[25px] h-[25px]'
                            alt='Active Microphone'
                          />
                        </button>
                      ) : (
                        <button
                          type='button'
                          onClick={toggleMuted}
                          className='z-10'
                        >
                          <span className='relative flex'>
                            <span className='animate-ping absolute inline-flex w-full h-full text-center rounded-full bg-blue opacity-75'></span>
                            <img
                              src={microphone}
                              className='w-[25px] h-[25px]'
                              alt='Muted Microphone'
                            />
                          </span>
                        </button>
                      )}
                    </>
                  )}
                  <div className='bg-gray-100/50 p-2 rounded-full w-[50px] h-[50px] ml-3 flex content-center justify-center'>
                    <button
                      id='endcallbutton'
                      type='button'
                      onClick={handleEndCallClick}
                    >
                      <span className='relative flex'>
                        <img
                          src={endCall}
                          className='w-[25px] h-[25px]'
                          alt='Stop Screen Record'
                        />
                      </span>
                    </button>
                  </div>
                  <div className='bg-gray-100/50 p-2 rounded-full w-[50px] h-[50px] ml-3 flex content-center justify-center'>
                    <button id='hiddenRecordButton' onClick={handleRecording}>
                      <span className='relative flex'>
                        <img
                          src={startRecord}
                          className='w-[25px] h-[25px]'
                          alt='Stop Screen Record'
                        />
                      </span>
                    </button>
                  </div>
                  {/* <div>
                    <button onClick={handleRecording}>
                      <span className='relative flex'>
                        <img
                          src={startRecord}
                          className='w-[25px] h-[25px]'
                          alt='Stop Screen Record'
                        />
                      </span>
                    </button>
                  </div> */}
                </div>

                {remoteStream && (
                  <div className='absolute bottom-2 flex justify-right w-[140px] right-[15px] video-screen rounded-md  border-2 z-[9999] border-[#6baafa] '>
                    {myStream && (
                      <>
                        <ReactPlayer
                          className='mx-auto'
                          playing
                          url={myStream}
                          width='100%'
                          height='auto'
                          muted
                        />
                        <div className='absolute bottom-0 flex justify-left z-[9999]'>
                          <h4 className='text-[10px] text-left text-white bg-black/[0.6] p-1'>
                            {userTypeValue === "staff"
                              ? staffFirstName
                              : userFirstName}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className='w-full h-full video-call'>
                  <ReactPlayer
                    className='mx-auto video-call-room-video'
                    playing
                    url={remoteStream ? remoteStream : myStream}
                    width='100%'
                    height='auto'
                    key={
                      remoteStream !== undefined && "id" in remoteStream
                        ? remoteStream?.id
                        : myStream?.id
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='h-full bg-gray-100 rounded-2xl'>
            <h4 className='text-[25px] text-center p-5'>No one in room</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCallRoom;
