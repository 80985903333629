import React, { useState, useEffect } from "react";

const PopupModal = ({setPopupendCall, popupendCall, handle, isFullScreen, setIsFullScreen, handleEndCallClickMain }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  
  const closeModal = () => {
    handle.enter();
    setIsFullScreen(true);
    setIsAnimating(false);
    setTimeout(() => setIsOpen(false), 300);
  };

  const handleConfirm = () => {
    handleEndCallClickMain();
    setIsFullScreen(false);
    handle.exit();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isFullScreen === false) {
      setIsOpen(true);
      setIsAnimating(true);
    }
    }, [isFullScreen]);
      
    useEffect(() => {
      if(popupendCall === true) {
        setIsOpen(false);
        setIsAnimating(false);
      }
    }, [popupendCall]);

    useEffect(() => {
      const interviewStatus = localStorage.getItem("interview_status", null);
      if(interviewStatus == "completed") {
        setIsOpen(false);
        setIsAnimating(false);
        setIsFullScreen(false);
        handle.exit();
      }
    }, []);
  return (
    <div>

      {isOpen && (
        <div
          className={`fixed inset-0 z-10 transition-opacity ${isAnimating
            ? "ease-out duration-300 opacity-100"
            : "ease-in duration-200 opacity-0"
          }`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${isAnimating
                    ? "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100"
                    : "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                }`}
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        className="text-red-600 w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title"
                      >
                        Are you sure?
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          By quitting this screen you are terminating your interview.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={handleConfirm}
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupModal;
