import React, { useState, useEffect } from "react";
import Header from "../header";
import { useNavigate, useLocation } from "react-router-dom";
import apiCall from "../../utils/axiosCall";
import { endPoint } from "../../utils/endPoint";

const Feedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [remark, setRemark] = useState("");
  const [formField, setFormField] = useState([]);
  const [ratings, setRatings] = useState({});
  const [averageRating, setAverageRating] = useState(0);
  const session_id_external_interview = localStorage.getItem("session_id");
  const [errorRender, setErrorRender] = useState("");
  const userType = localStorage.getItem("userType");
  const staff_uuid = localStorage.getItem("staff_uuid");

  useEffect(() => {
    if(location.state) {
      navigate(`/feedback/`, { replace: true });
      window.location.reload();
    }
  }, [location.state]);

  useEffect(() => {
    if (userType !== "staff") {
      navigate("/thank-you");
    }
    fetchFeedbackFormDetail();
  }, []);

  const fetchFeedbackFormDetail = async () => {
    let data = {
      session_id: session_id_external_interview,
    };

    try {
      const getFeedbackFormDetail = await apiCall({
        url: endPoint.EXTERNAL_INTERVIEW_FETCH_FEEDBACK_FORM,
        method: "post",
        data: JSON.stringify(data),
      });

      const decryptedDetails = getFeedbackFormDetail;
      console.log(decryptedDetails);

      if (decryptedDetails.return_code === "fetched_data_successfully") {
        const response = decryptedDetails?.result?.feedbackCategory?.map(
          (assessment) => ({
            ...assessment,
            value: 0,
          })
        );

        setFormField(response || []);

        const initialRatings = {};
        response.forEach((assessment) => {
          const assessmentType = assessment.title
            .toLowerCase()
            .replace(/ /g, "");
          initialRatings[assessmentType] = { value: 0 };
        });
        setErrorRender("");
        setRatings(initialRatings);
      } else {
        setErrorRender("Oops! Something went wrong...");
        console.error("Data not found.");
      }
    } catch (error) {
      console.error("Error fetching feedback form details:", error);
    }
  };

  useEffect(() => {
    const totalRatings = Object.values(ratings).filter(
      (rating) => rating?.value !== null
    );
    if (totalRatings.length > 0) {
      const sum = totalRatings.reduce((acc, rating) => acc + rating.value, 0);
      const avg = (sum / totalRatings.length).toFixed(2);
      setAverageRating(avg);
    } else {
      setAverageRating(0);
    }
  }, [ratings]);

  const handleRatingChange = (assessmentType, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [assessmentType]: { value },
    }));

    setFormField((prevFormField) =>
      prevFormField.map((assessment) => {
        const type = assessment.title.toLowerCase().replace(/ /g, "");
        if (type === assessmentType) {
          return {
            ...assessment,
            value: value,
          };
        }
        return assessment;
      })
    );
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const handleSubmit = async () => {
    try {
        if(remark) {
            let data = {
              session_id: session_id_external_interview,
              feedback_category: {
                feedbackCategory: formField,
              },
              remark: remark,
              avarage: averageRating,
              user_id: staff_uuid,
            };
            console.log(data);
      
            const submitFeedbackForm = await apiCall({
              url: endPoint.EXTERNAL_INTERVIEW_SUBMIT_FEEDBACK_FORM,
              method: "post",
              data: JSON.stringify(data),
            });
            const decryptedDetails = submitFeedbackForm;
      
            if (decryptedDetails.return_code === "fetched_data_successfully") {
              setErrorRender("");
              navigate("/thank-you/");
            } else {
              if(decryptedDetails.message) {
                  setErrorRender(decryptedDetails.message);
              } else {
                  setErrorRender("Oops! Something went wrong...");
              }
            }
        } else {
            setErrorRender("Please enter value for remark.");
        }
    } catch (error) {
      console.error("Error fetching feedback form details:", error);
    }
  };

  const renderAssessmentInput = (assessment, index) => {
    const assessmentType = assessment.title.toLowerCase().replace(/ /g, "");
    const isLastItem = index === formField.length - 1;

    return (
      <div
        className={`mb-2 ${!isLastItem ? "border-b-2" : ""}`}
        key={assessment.id}
      >
        <div className='sm:flex justify-between items-center pb-4'>
          <label className='text-gray-700'>{assessment.title}</label>
          <div className='grid grid-cols-5 gap-2 rounded-xl border-solid border-2 border-gray-200 p-1 px-2 mt-2'>
            {[1, 2, 3, 4, 5].map((num) => (
              <div key={num}>
                <input
                  type='radio'
                  id={`${assessmentType}-${num}`}
                  name={assessmentType}
                  value={num}
                  checked={ratings[assessmentType]?.value === num}
                  onChange={() => handleRatingChange(assessmentType, num)}
                  className='peer hidden'
                />
                <label
                  htmlFor={`${assessmentType}-${num}`}
                  className='block cursor-pointer select-none p-2 w-[40px] text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-[#045de8] peer-checked:rounded-lg peer-checked:bg-[#045be84d]'
                >
                  {num}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <div className='max-w-[40rem] mx-auto bg-white rounded-b-lg shadow-md mt-3'>
        <div className='bg-gray-100 p-3 rounded-t-lg font-bold text-[20px]'>
          <p>Feedback</p>
        </div>
        <div className='p-6 rounded-t-lg'>
          <div className='mb-4'>
            <div className='flex justify-between items-center pb-1 '>
              <label className='text-[#8f8f8f]'>Categories</label>
              <div className='p-1 px-2'>
                <p>
                  Average Rating: <span>{averageRating}</span>/<span>5</span>
                </p>
              </div>
            </div>
          </div>

          {formField.map((assessment, index) =>
            renderAssessmentInput(assessment, index)
          )}

          <div className='mb-4'>
            <div className='relative w-full'>
              <textarea
                rows='4'
                id='remark'
                className='block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-[#e5e7eb]'
                placeholder='Remark *'
                value={remark}
                onChange={handleRemarkChange}
              />
            </div>
          </div>
          <div className='text-center'>
            <p className='m-2 text-red-600'>{errorRender ? errorRender : ""}</p>
          </div>
          <div className='text-center'>
            <button onClick={handleSubmit} className='submit-btn'>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
