// Reducer for MediaStream
const mediaStreamReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_MEDIA_STREAM":
      return action.payload;
    default:
      return state;
  }
};

export default mediaStreamReducer;
