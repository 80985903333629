import CryptoJS from "crypto-js";

function decryptMessage(encryptedMessage) {
  // const DecryptMessage = () => {
  //   const encryptedMessage = "NrutA2QE3+o3Z3avCI0oTaG5LxsUrqcOhDfqROlfhLc=";
  // console.log("encryptedMessage IN DecrypLOGIC", encryptedMessage);
  const encryptionKey = process.env.REACT_APP_KEY;
  const encryptionIV = process.env.REACT_APP_IV;
  //   console.log(encryptionKey, encryptionIV);
  // Convert key and IV to WordArray
  const key = CryptoJS.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJS.enc.Utf8.parse(encryptionIV);
  // Decode base64 encoded message
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedMessage);
  // Decrypt
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const jsonString = decrypted.toString(CryptoJS.enc.Utf8);
  const jsonObject = JSON.parse(jsonString);
  // console.log("jsonObject", jsonObject);
  // Convert decrypted WordArray to Utf8 string
  return jsonObject; //<p>Output {output}</p>;
}

export default decryptMessage;
