// TURN SERVER LIST https://gist.github.com/sagivo/3a4b2f2c7ac6e1b5267c2f1f59ac6c6b
// import { useSocket } from "../../../context/SocketProvider";
class PeerService {
  constructor() {
    if (!this.peer) {
      this.peer = new RTCPeerConnection({
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:stun1.l.google.com:19302" },
          { urls: "stun:stun2.l.google.com:19302" },
          { urls: "stun:stun3.l.google.com:19302" },
          { urls: "stun:stun4.l.google.com:19302" },
          { urls: "stun:stun.stunprotocol.org:3478" },
          { urls: "stun:stun.jap.bloggernepal.com:5349" },
          { urls: "stun:global.stun.twilio.com:3478" },
        ],
      });

      this.peer._debug = console.log;
      // Handling ICE candidate event
      // this.peer.onicecandidate = (event) => {
      //   if (event.candidate) {
      //     this.sendIceCandidate(event.candidate);
      //   }
      // };
    }
  }
  // setSocket(socket, otherPeerId) {
  //   this.socket = socket;
  //   this.otherPeerId = otherPeerId;
  // }

  sendIceCandidate(candidate) {
    if (this.socket && this.otherPeerId) {
      this.socket.send(
        JSON.stringify({
          type: "ice:candidate",
          to: this.otherPeerId,
          candidate: candidate,
        })
      );
    }
  }

  // Method to handle receiving ICE candidates
  async addIceCandidate(candidate) {
    if (this.peer) {
      try {
        await this.peer.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (e) {
        console.error("Error adding received ICE candidate", e);
      }
    }
  }

  async getAnswer(offer) {
    if (this.peer) {
      // console.log("GETANSWER");
      await this.peer.setRemoteDescription(offer);
      const ans = await this.peer.createAnswer();
      await this.peer.setLocalDescription(new RTCSessionDescription(ans));
      return ans;
    }
  }

  async setLocalDescription(ans) {
    if (this.peer) {
      // console.log("SETLOCALERROR");a

      await this.peer.setRemoteDescription(new RTCSessionDescription(ans));
    }
  }

  async getOffer() {
    if (this.peer) {
      const offer = await this.peer.createOffer();
      await this.peer.setLocalDescription(new RTCSessionDescription(offer));
      return offer;
    }
  }
  close() {
    if (this.peer) {
      // Close the peer connection
      this.peer.close();

      // Set the peer property to null
      this.peer = null;
    }
  }
}

export default new PeerService();
