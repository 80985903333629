// Import dependance here
import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import files here
import Interview from "./interview/index";
import AutoScreenInterview from "./component/AutoScreen/interview/index";

import OtpScreen from "./component/OtpScreen/OtpScreen";

import GuideLines from "./component/GuideLines/GuideLines";
import AutoScreenGuideLines from "./component/AutoScreen/GuideLines/GuideLines";

import PageNotFound from "./component/PageNotFound/PageNotFound";
import ThankYou from "./component/ThankYou/ThankYou";
import Feedback from "./component/Feedback/Feedback";
import { Provider } from "react-redux";
import store from "./redux/store";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import VideoCallOtpScreen from "./component/VideoCall/VideoCallOtpScreen/VideoCallOtpScreen";
import VideoCallGuidelines from "./component/VideoCall/VideoCallGuidelines/VideoCallGuidelines";
import VideoCallLobbyScreen from "./component/VideoCall/VideoCallLobbyScreen/VideoCallLobbyScreen";
import VideoCallRoom from "./component/VideoCall/VideoCallRoom/VideoCallRoom";

import ExternalInterviewOtpScreen from "./component/ExternalInterview/ExternalInterviewOtpScreen/ExternalInterviewOtpScreen";
import ExternalInterviewGuidelines from "./component/ExternalInterview/ExternalInterviewGuidelines/ExternalInterviewGuidelines";
import ExternalInterviewLobbyScreen from "./component/ExternalInterview/ExternalInterviewLobbyScreen/ExternalInterviewLobbyScreen";
import ExternalInterviewRoom2 from "./component/ExternalInterview/ExternalInterviewRoom/ExternalInterviewRoom2";

import { SocketProvider } from "./context/SocketProvider";

function App() {
  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenChange = (state) => {
    setIsFullScreen(state);
  };

  return (
    <FullScreen handle={handle} onChange={handleFullScreenChange}>
      <div className='h-screen bg-[#fff]'>
        <script src='https://www.webrtc-experiment.com/screenshot.js'></script>
        <Provider store={store}>
          <Router>
            <SocketProvider>
              <Routes>
                <Route
                  path='/:uuid'
                  element={<OtpScreen allowedOtpLength={6} />}
                />
                <Route
                  path='/guidelines/:uuid'
                  element={
                    <GuideLines
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                <Route
                  path='/interview/:uuid'
                  element={
                    <Interview
                      isFullScreen={isFullScreen}
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                <Route path='/thank-you' element={<ThankYou />} />
                <Route path='/feedback' element={<Feedback />} />
                <Route path='/page-not-found' element={<PageNotFound />} />

                {/*  video call url start  */}
                <Route
                  path='/video-call-otp'
                  element={<VideoCallOtpScreen />}
                />
                <Route path='/guidelines' element={<VideoCallGuidelines />} />
                <Route
                  path='/video-call-lobby'
                  element={<VideoCallLobbyScreen />}
                />
                <Route
                  path='/video-call-room/:id'
                  element={<VideoCallRoom />}
                />
                <Route
                  path='/autoscreen/guidelines/:uuid'
                  element={
                    <AutoScreenGuideLines
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                <Route
                  path='/autoscreen/interview/:uuid'
                  element={
                    <AutoScreenInterview
                      isFullScreen={isFullScreen}
                      handle={handle}
                      setIsFullScreen={setIsFullScreen}
                    />
                  }
                />
                {/*  video call url end  */}

                {/*  External Interview url start  */}
                <Route
                  path='/external-interview-otp-screen'
                  element={<ExternalInterviewOtpScreen />}
                />
                <Route
                  path='/external-interview-guidelines'
                  element={<ExternalInterviewGuidelines />}
                />
                <Route
                  path='/external-interview-lobby'
                  element={<ExternalInterviewLobbyScreen />}
                />
                <Route
                  path='/external-interview-room/:id'
                  element={<ExternalInterviewRoom2 />}
                />
                {/*  External Interview url end  */}
              </Routes>
            </SocketProvider>
          </Router>
        </Provider>
      </div>
    </FullScreen>
  );
}

export default App;
